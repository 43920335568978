'use strict';
import _ from 'src/domain/libs/util';

// 無料ユーザーか？
const _isFree = (profile = {}): boolean => {
  // 未ログイン、無料、無料（トライアル不可）
  // @ts-ignore TS2339
  return !profile.userStatus || profile.userStatus == 0 || profile.userStatus == 4;
};

// 無料ユーザーか？
export function isFree(profile = {}): boolean {
  return _isFree(profile);
}

// トライアル不可か？
export function isTrialDisallow(profile = {}) {
  // （トライアル不可）
  // @ts-ignore TS2339
  return profile.userStatus == 4;
}

// 無料トライアル不可のタグを表示するか？
export function showNotTrial(profile = {}, models = {}) {
  const badgeConfig = _.get(models, 'config.data.badge') || {};
  // 未ログイン、無料
  // @ts-ignore TS2339
  if (!profile.userStatus || profile.userStatus == 0) {
    // @ts-ignore TS2339
    return !badgeConfig.hide_non_target_free_trial_badge_for_free_user_flag;
    // トライアル
    // @ts-ignore TS2339
  } else if (profile.userStatus == 1) {
    // @ts-ignore TS2339
    return !badgeConfig.hide_non_target_free_trial_badge_for_contract_user_flag;
  }
  return false;
}

// 放送契約限定が再生可能か？(cas登録があるかないか)
export function canPlayBroadcast(profile = {}) {
  // @ts-ignore TS2339
  return profile.userStatus == 3 || (profile.userStatus == 2 && profile.isBs);
}

// permitUserStatusに一致する文字列を返却
export function permitUserStatus(profile = {}) {
  // 0: 無料 free, 1 トライアル trial, 2 配信契約 stream, 3 放送契約 broadcast
  const permitMap = { 0: 'free', 1: 'trial', 2: 'stream', 3: 'broadcast', 4: 'trial_used' };
  // @ts-ignore TS2339
  let userStatus = profile.userStatus;
  // 配信契約でcas登録済は放送契約視聴可能
  // @ts-ignore TS2339
  if (userStatus == 2 && profile.isBs) userStatus = 3;
  console.log(permitMap[userStatus]);
  return permitMap[userStatus];
}

export function getUserStatus(authContext) {
  return _.get(authContext, 'status', '-1');
}

export default function(models: any, defaultVal?: any) {
  const activeProfile = _.get(models, 'memberContext.data.profileData.active');
  if (!!activeProfile) return activeProfile;
  if (_.get(models, 'ignoreSession')) return defaultVal;
  const profileId = _.get(models, 'session.selectedProfileId');
  if (!!profileId) {
    return { id: profileId, refId: _.get(models, 'session.selectedProfileRefId') };
  }
  return defaultVal;
}
