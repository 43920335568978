/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';

export const USE_LAST_EVALUATED_KEY = ['continuewatching', 'mylist', 'mylistattr'];

export const PAGING_INITIAL_KEY = 'initial';

export const LIVE_RANGE = 10;

export const DEVICE_TYPE = {
  TV: 'tv',
  CAR_MONITOR: 'car_monitor',
} as const;
