import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';
import shallowEqualFuzzy from 'shallow-equal-fuzzy';

import { Palette as TPalette } from '@domain/models/palette';
import MainViewLink from '../../../common/components/MainViewLink';
import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import RowHeader from './RowHeader';
import Row from './Row';
import TitleCard from './TitleCard';
import LiveCard from './LiveCard';
import StudioCard from './StudioCard';
import EventCard from './Epg/EventCard';
import LoadingRow from './../loader/LoadingRow';
import LoadingTitle from './../loader/LoadingTitle';
import AdvertisingCard from './AdvertisingCard';
import Ranking from './Ranking';
import PaletteContent from './PaletteContent';
import GenreContent from './GenreContent';
import GalleryContent from './GalleryContent';
import Attribute from './Attribute';

import ViewingBookmarkDeleteApp from '../../../../common/ViewingBookmarkDeleteApp';
import { CardContextProvider } from '../../../common/context/CardContext';
import routes from '../../../common/routes';

import { CONTENT_EVENTS, CLICK_AREA } from '../../../../common/GtmApp';
import { PAGING_INITIAL_KEY, USE_LAST_EVALUATED_KEY } from '../../../../constants';
import { parseUrl } from '../../../../sketch-platform/ui/routing';
import PaletteImpression from './PaletteImpression';
import activeProfile from 'src/utils/activeProfile';

const indexes = function(models, options, props) {
  let toNum;
  if (_.get(models.browserInfo, 'data') && (models.browserInfo.data.isIOS || models.browserInfo.data.isAndroid)) {
    if (_.includes(USE_LAST_EVALUATED_KEY, _.get(props, 'listContext', '').toLowerCase())) {
      toNum = props && props.toNum ? props.toNum : options && options.numItems ? options.numItems : 18;
    } else {
      toNum =
        props && props.toNum ? props.toNum - (props.fromNum || 0) : options && options.numItems ? options.numItems : 18;
    }
  } else {
    toNum = props && props.toNum && props.toNum > 18 ? 39 : 18;
  }
  return { from: (props && props.fromNum) || 0, to: toNum };
};

class Palette extends React.Component {
  __palette?: TPalette;
  static getPaths = function(models, options, props) {
    if (!props.paletteId) {
      return [
        [
          [
            'id',
            'objects',
            'idKey',
            'title',
            'description',
            'genre',
            'person',
            'team',
            'league',
            'studio',
            'attribute',
            'linearChannelMeta',
            'listContext',
            'sortOrder',
            'filterType',
            'numOfRows',
            'titleType',
            'textArea',
            'tips',
            'viewType',
            'popCard',
            'badgeOnCanvas',
            'artType',
            'cardSize',
            'hideAll',
            'artKind',
            'meta',
            'mbListLayout',
            'showSortFlag',
            'cardTitleDisplaySetting',
            'period',
            'recommendKey',
            'linkCanvas',
            'linkUrl',
            'elementId',
            'genres',
            'middleGenres',
            'defaultContents',
            'ratingCodes',
            'tSearch',
            'sSearch',
            'subscriptionHistoryType',
          ],
        ],
      ];
    }

    const listContext = props.listContext && props.listContext.length > 0 ? props.listContext.toLowerCase() : undefined;
    if (listContext) {
      const paths = [
        [
          'palette',
          props.paletteId,
          [
            'id',
            'idKey',
            'schemaId',
            'title',
            'description',
            'listContext',
            'linearChannelMeta',
            'sortOrder',
            'filterType',
            'numOfRows',
            'titleType',
            'textArea',
            'tips',
            'viewType',
            'popCard',
            'badgeOnCanvas',
            'artType',
            'cardSize',
            'hideAll',
            'artKind',
            'meta',
            'showSortFlag',
            'cardTitleDisplaySetting',
            'linkCanvas',
            'linkUrl',
            'elementId',
            'genres',
            'middleGenres',
            'defaultContents',
            'tSearch',
            'sSearch',
            'subscriptionHistoryType',
          ],
        ],
      ];
      if (
        listContext === 'genre' ||
        listContext === 'person' ||
        listContext === 'team' ||
        listContext === 'league' ||
        listContext === 'studio' ||
        listContext === 'attr'
      ) {
        if (this.getListContextId(props)) {
          const listContextId = this.getListContextId(props);
          const searchOptions = Attribute.searchOptions(props, models);
          // @ts-ignore TS2339
          return TitleCard.getPaths()
            .map(function(path) {
              return [
                listContext,
                listContextId,
                searchOptions.filterType,
                searchOptions.sortOrder,
                searchOptions.searchFilter,
                indexes(models, options, props),
              ].concat(path);
            })
            .concat(paths)
            .concat([
              [
                listContext,
                listContextId,
                searchOptions.filterType,
                searchOptions.sortOrder,
                searchOptions.searchFilter,
                'length',
              ],
            ])
            .concat([['palette', props.paletteId, ['genre', 'person', 'team', 'league', 'studio', 'attribute']]]);
        }
        return paths;
      } else if (listContext === 'similars' || listContext === 'more_like_this') {
        if (props.meta) {
          const sortOrder = props.sortOrder && props.sortOrder.length > 0 ? props.sortOrder : 'po';
          const listContextId = props.meta;
          // @ts-ignore TS2339
          return TitleCard.getPaths()
            .map(function(path) {
              return [listContext, listContextId, sortOrder, indexes(models, options, props)].concat(path);
            })
            .concat(paths)
            .concat([[listContext, listContextId, sortOrder, 'length']])
            .concat([['palette', props.paletteId, ['meta']]]);
        }
        return paths;
      } else if (listContext === 'relateds') {
        if (props.meta) {
          const sortOrder = props.sortOrder && props.sortOrder.length > 0 ? props.sortOrder : 'su';
          const listContextId = props.meta;
          // @ts-ignore TS2339
          return TitleCard.getPaths()
            .map(function(path) {
              return ['meta', listContextId, listContext, sortOrder, indexes(models, options, props)].concat(path);
            })
            .concat(paths)
            .concat([['meta', listContextId, listContext, sortOrder, 'length']])
            .concat([['palette', props.paletteId, ['meta']]]);
        }
        return paths;
      } else if (listContext === 'editorial') {
        // @ts-ignore TS2339
        return TitleCard.getPaths()
          .map(path => {
            return ['palette', props.paletteId, 'objects', indexes(models, options, props)].concat(path);
          })
          .concat(
            // @ts-ignore TS2339
            AdvertisingCard.getPaths().map(path => {
              return ['palette', props.paletteId, 'objects', indexes(models, options, props)].concat(path);
            }),
          )
          .concat(paths)
          .concat([
            ['palette', props.paletteId, ['title', 'description']],
            ['palette', props.paletteId, 'objects', 'length'],
          ]);
      } else if (listContext === 'ranking') {
        const paths = [
          [
            'palette',
            props.paletteId,
            [
              'id',
              'title',
              'description',
              'genre',
              'person',
              'team',
              'league',
              'studio',
              'attribute',
              'listContext',
              'period',
              'numOfRows',
              'titleType',
              'textArea',
              'tips',
              'viewType',
              'popCard',
              'badgeOnCanvas',
              'artType',
              'cardSize',
              'hideAll',
              'artKind',
              'cardTitleDisplaySetting',
              'linkCanvas',
              'linkUrl',
              'elementId',
              'genres',
              'middleGenres',
              'defaultContents',
              'mbListLayout',
              'ratingCodes',
              'tSearch',
              'sSearch',
              'subscriptionHistoryType',
            ],
          ],
        ];
        if (props.period) {
          props.searchFilter = _.isEmpty(props.ratingCodes) ? 'p:a' : `rc:${props.ratingCodes.join(',')}`;
          return paths.concat(Ranking.getPaths(models, options, props));
        }
        return paths;
      } else if (listContext === 'user_recommend') {
        // pathsにrecommendKeyを追加する
        _.last(_.first(paths)).push('recommendKey');
        const rootPath = [listContext];
        // @ts-ignore TS2339
        return TitleCard.getPaths()
          .map(path => {
            return rootPath
              .concat([props.recommendKey])
              .concat([indexes(models, options, props)])
              .concat(path);
          })
          .concat(paths)
          .concat([rootPath.concat([props.recommendKey, 'length'])])
          .concat([rootPath.concat([props.recommendKey, 'palette_title'])]);
      } else if (listContext !== undefined && listContext.length > 0) {
        const rootPath = [listContext];
        let sortOrder = props.sortOrder && props.sortOrder.length > 0 ? props.sortOrder : null;
        if (listContext === 'studiolist' && !sortOrder) {
          sortOrder = 'su';
        }
        if (sortOrder) {
          rootPath.push(sortOrder);
        }
        if (listContext === 'recommended') {
          rootPath.push(props.elementId);
          rootPath.push(props.genres || 0);
          rootPath.push(props.defaultContents || 0);
        }
        if (listContext === 'studiolist') {
          // @ts-ignore TS2554
          return StudioCard.getPaths()
            .map(path => {
              return rootPath.concat([indexes(models, options, props)]).concat(path);
            })
            .concat(paths)
            .concat([rootPath.concat(['length'])]);
        } else if (-1 !== _.indexOf(['nowonair', 'timeline', 'fromnow', 'schedule'], listContext)) {
          let _rootPath;
          if (listContext === 'nowonair') {
            rootPath.push(props.linearChannelMeta);
          } else {
            rootPath.push(props.linearChannelMeta);
            paths.push(['meta', props.linearChannelMeta, ['id', 'name', 'refId', 'thumbnailUrl']]);
          }
          // @ts-ignore TS2339
          return EventCard.getPaths()
            .map(path => {
              return rootPath.concat([indexes(models, options, props)]).concat(path);
            })
            .concat(paths)
            .concat([rootPath.concat(['length'])]);
        } else {
          let _rootPath = _.clone(rootPath);
          let pagingKeyPath = [];
          if (_.includes(USE_LAST_EVALUATED_KEY, listContext)) {
            _rootPath.push(props.lastEvaluatedKey || PAGING_INITIAL_KEY);
            const { from, to } = indexes(models, options, props);
            _rootPath.push(to - from + 1);
            pagingKeyPath = pagingKeyPath.concat([_rootPath.concat(['lastEvaluatedKey'])]);
          }
          // @ts-ignore TS2339
          const titlePaths = TitleCard.getPaths()
            .map(path => {
              return _rootPath.concat([indexes(models, options, props)]).concat(path);
            })
            .concat(paths);
          if (listContext == 'recommended') {
            return titlePaths;
          }
          const _paths = titlePaths.concat([_rootPath.concat(['length'])]);
          return _.isEmpty(pagingKeyPath) ? _paths : _paths.concat(pagingKeyPath);
        }
      }
    }
    return [
      [
        'palette',
        props.paletteId,
        [
          'id',
          'title',
          'description',
          'sortOrder',
          'listContext',
          'filterType',
          'numOfRows',
          'titleType',
          'textArea',
          'tips',
          'viewType',
          'popCard',
          'badgeOnCanvas',
          'artType',
          'artKind',
          'meta',
          'showSortFlag',
          'cardTitleDisplaySetting',
          'period',
          'ratingCodes',
          'tSearch',
          'sSearch',
          'genre',
          'person',
          'subscriptionHistoryType',
        ],
      ],
    ];
  };

  // paletteのgenreかpersonかstudioのidを取得
  static getListContextId = function(props) {
    return props.genre || props.person || props.team || props.league || props.studio || props.attribute || null;
  };

  static get propTypes() {
    return {
      paletteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      listContext: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
      enablePaginationIndicator: PropTypes.bool,
      enablePushOut: PropTypes.bool,
      noContents: PropTypes.func,
      deliveryStartVisible: PropTypes.bool,
      orderNum: PropTypes.number,
      shouldSkipGtm: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      enablePaginationIndicator: true,
      enablePushOut: false,
      useRowHeader: true,
      shouldSkipGtm: false,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      columnsInRow: PropTypes.number,
      popType: PropTypes.string,
      isInitialRender: PropTypes.bool,
      isOverlayPage: PropTypes.bool,
      models: PropTypes.object,
      routeHandler: PropTypes.object,
      routeHandlers: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  static get childContextTypes() {
    return {
      isTallRow: PropTypes.bool,
      titleType: PropTypes.string,
      textArea: PropTypes.string,
      tips: PropTypes.string,
      viewType: PropTypes.string,
      showBadge: PropTypes.bool,
      artType: PropTypes.string,
      artKind: PropTypes.string,
      cardSize: PropTypes.string,
      listContext: PropTypes.string,
      deleteApp: PropTypes.object,
      routeHandlers: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.maxItems = 40;

    this.handleSelectPeriodChange = this.handleSelectPeriodChange.bind(this);
    this.handleSortOrderChange = this.handleSortOrderChange.bind(this);
    this.handleDeleted = this.handleDeleted.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);

    this.totalItems = 0;
    // @ts-ignore TS2339
    this.rejectCount = 0;
    this.palette = props.model.getSync(`palette.${props.paletteId}`);
    this.checkOrientation = _.throttle(this.checkOrientation.bind(this), 300, { leading: true, trailing: true });

    this.state = {
      dispose: null,
      fetchDataError: null,
      fullDataLoaded: false,
      generation: -1,
      key: '',
      prevGeneration: 1,
      prevKey: '',
      isDataLoaded: false,
      loading: false,
      selectPeriod: this.palette && this.palette.listContext === 'ranking' ? this.palette.period : null,
      changingSelectPeriod: false,
      sortOrder: this.palette ? this.palette.sortOrder : null,
      changingSortOrder: false,
      isPortrait: false,
    };

    if (props.listContext === 'continueWatching') {
      // @ts-ignore TS2339
      this._deleteApp = new ViewingBookmarkDeleteApp(context.getModelData('services', 'cms'), this.context.gtmApp);
    }
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      this.checkOrientation();
    }
  }

  getChildContext() {
    if (this.palette) {
      return {
        // @ts-ignore TS2339
        listContext: this.props.listContext,
        titleType: this.palette.titleType || 'name',
        // @ts-ignore TS2339
        textArea: !!this.props.textArea
          ? // @ts-ignore TS2339
            this.props.textArea
          : this.palette.textArea
          ? this.palette.textArea
          : 'hidden',
        tips: this.palette.tips || 'hidden',
        viewType: this.palette.viewType || 'slider',
        showBadge: this.palette.badgeOnCanvas !== false,
        artType: this.palette.artType || 'default',
        artKind: this.palette.artKind || 'default',
        cardSize: this.palette.cardSize || 'medium',
        // @ts-ignore TS2339
        isTallRow: this.props.listContext === 'originals',
        // @ts-ignore TS2339
        deleteApp: this._deleteApp,
        routeHandlers: this.context.routeHandlers,
      };
    } else {
      return {
        // @ts-ignore TS2339
        listContext: this.props.listContext,
        // @ts-ignore TS2339
        isTallRow: this.props.listContext === 'originals',
        // @ts-ignore TS2339
        deleteApp: this._deleteApp,
        routeHandlers: this.context.routeHandlers,
      };
    }
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (this._deleteApp) {
      // @ts-ignore TS2339
      this._deleteApp.on('onDeleted', this.handleDeleted);
    }
    // mount時にcacheから復元できる場合はfetchしない
    if (!this.items) this.fetchData(this.props);

    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkOrientation);
    }
    this.checkOrientation();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // console.log('shouldComponentUpdate')
    if (
      !shallowEqualFuzzy(this.props, nextProps) ||
      !shallowEqualFuzzy(this.state, nextState) ||
      !shallowEqualFuzzy(this.context, nextContext)
    ) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this._deleteApp) {
      // @ts-ignore TS2339
      this._deleteApp.off('onDeleted', this.handleDeleted);
    }
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();

    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkOrientation);
    }

    // 視聴中の初回キャッシュ、
    // fetchから5秒以内だと残ってしまうので消す
    // TODO 暫定対応 falcorの$expirexのみで対応したい
    // @ts-ignore TS2339
    const listContext = this.props.listContext ? this.props.listContext.toLowerCase() : null;
    if (
      listContext === 'continuewatching' &&
      _.has(this.props, `model._root.cache.${listContext}.${PAGING_INITIAL_KEY}`)
    ) {
      // @ts-ignore TS2339
      this.props.model.invalidate([listContext, PAGING_INITIAL_KEY]);
    }
  }

  checkOrientation() {
    if (typeof window === 'undefined') return;

    const browserInfo = this.context.getModelData('browserInfo');
    if (!(browserInfo.isIOS || browserInfo.isAndroid)) {
      this.setState({ isPortrait: true });
    } else {
      let angle = screen && screen.orientation && screen.orientation.angle;
      let isPortrait = false;

      if (angle === undefined) {
        angle = window.orientation;

        if (angle === undefined) {
          if (window.innerHeight > window.innerWidth) {
            angle = 0;
          } else {
            angle = 90;
          }
        }
      }
      isPortrait = angle === 0;

      // @ts-ignore TS2339
      if (this._isMounted) {
        this.setState({ isPortrait });
      } else {
        // @ts-ignore TS2339
        this.state.isPortrait = isPortrait;
      }
    }
  }

  handleDeleted() {
    // キャッシュされている視聴中一覧を削除する
    // @ts-ignore TS2339
    this.props.model.invalidate(['continuewatching']);
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    // 撮り直す
    this.setState({ dispose: null, fetchDataError: null, generation: -1 }, () => {
      // @ts-ignore TS2551
      delete this.__items;
      delete this.totalItems;
      // @ts-ignore TS2339
      delete this.rejectCount;
      // @ts-ignore TS2339
      delete this.lastEvaluatedKey;
      this.fetchData(this.props);
    });
  }

  handleItemClick(item) {
    this.sendToGtm(item);
  }

  sendToGtm(item) {
    if (!_.get(this.context, 'gtmApp')) return;
    const schemaType = _.get(item, 'type');
    const isLinearChannelSchema = schemaType === 'linear_channel';
    const hasRelationProgram = _.get(item, 'seriesMeta') && _.get(item, 'seasonMeta');
    const channel = isLinearChannelSchema
      ? {
          refId: _.get(item, 'refId'),
          name: _.get(item, 'name'),
        }
      : _.get(item, 'linearChannelMeta');
    const relationProgram = isLinearChannelSchema
      ? null
      : hasRelationProgram
      ? {
          refId: _.get(item, 'seriesMeta.refId'),
          name: _.get(item, 'seriesMeta.name'),
        }
      : null;
    const program = isLinearChannelSchema
      ? null
      : schemaType === 'group'
      ? {
          refId: _.get(item, 'refId'),
          name: _.get(item, 'name'),
        }
      : {
          refId: _.get(item, 'seasonMeta.refId') || _.get(item, 'seriesMeta.refId'),
          name: _.get(item, 'seasonMeta.name') || _.get(item, 'seriesMeta.name'),
        };
    let content = null;
    let ad = null;
    if (_.get(item, 'advertisingId')) {
      ad = {
        id: _.get(item, 'advertisingId'),
        name: _.get(item, 'name'),
      };
    } else {
      content = isLinearChannelSchema
        ? {}
        : schemaType === 'group'
        ? {}
        : _.isEmpty(channel)
        ? {
            refId: _.get(item, 'refId'),
            name: _.get(item, 'name') || _.get(item, 'shortName'),
            rental: _.get(item, 'rental'),
            subscription: _.get(item, 'subscription'),
          }
        : {
            refId: _.get(item, 'uniqueId'),
            name: _.get(item, 'name'),
          };
    }

    const shelf = {
      id: _.get(this.props, 'paletteId'),
      name: _.get(this.palette, 'title'),
      order: _.get(this.props, 'orderNum'),
    };
    const attributes = _.get(item, 'attributes');
    const genres = _.get(item, 'genres');
    const middleGenres = _.get(item, 'middleGenres');
    const schemaId = _.get(item, 'schemaId');
    this.context.gtmApp.pushDataLayerOnContentPageClick(
      CONTENT_EVENTS.CONTENT_CLICK,
      { channel, program, shelf, content, relationProgram, attributes, genres, middleGenres, schemaId, ad },
      { clickArea: this.context.listType ? CLICK_AREA.LIST : CLICK_AREA.SHELF },
    );
  }

  render() {
    // console.log('render: ' + this.props.paletteId)
    const browserInfo = this.context.getModelData('browserInfo');
    let items = this.items || [];
    let totalItems = this.totalItems;
    // @ts-ignore TS2339
    const ignoreIds = this.props.ignoreIds ? _.compact(this.props.ignoreIds) : [];
    if (!_.isEmpty(ignoreIds)) {
      let ignoredCount = 0;
      items = _.filter(items, item => {
        if (_.isNil(item)) return false;
        let flag = !ignoreIds.includes(item.id);
        if (!flag) ignoredCount++;
        return flag;
      });
      if (ignoredCount > 0 && totalItems > 0) {
        totalItems = totalItems - ignoredCount;
      }
    }
    // @ts-ignore TS2339
    const listContext = this.props.listContext ? this.props.listContext.toLowerCase() : null;

    const headerProps = {};
    // @ts-ignore TS2339
    if (this.palette && this.props.useRowHeader) {
      // @ts-ignore TS2339
      headerProps.id = this.constructor.getListContextId(this.palette);
      // @ts-ignore TS2339
      headerProps.hideAll = this.palette.hideAll;
      if (-1 !== _.indexOf(['timeline', 'fromnow', 'schedule'], listContext)) {
        // @ts-ignore TS2339
        headerProps.id = this.palette.linearChannelMeta;
        // @ts-ignore TS2339
        headerProps.linearChannelMeta = this.linearChannelMeta;
        // @ts-ignore TS2339
      } else if (this.props.listContext == 'editorial' || this.palette.meta) {
        // @ts-ignore TS2339
        headerProps.id = this.props.paletteId;
        // @ts-ignore TS2339
        headerProps.listContext = 'editorial';
      }
      const query = {};
      // @ts-ignore TS2367
      if (this.palette.sortOrder && !this.palette.sortOrder !== 'su') {
        query['so'] = this.palette.sortOrder;
      }
      // @ts-ignore TS2367
      if (this.palette.filterType && !this.palette.filterType !== 'a') {
        query['ft'] = this.palette.filterType;
      }
      // @ts-ignore TS2339
      if (this.props.title) {
        // @ts-ignore TS2339
        headerProps.title = this.props.title;
      } else if (this.palette.title) {
        // @ts-ignore TS2339
        headerProps.title = this.palette.title;
      } else if (this.palette.schemaId == 14) {
        // @ts-ignore TS2339
        headerProps.title = '関連作品';
      }
      // @ts-ignore TS2339
      headerProps.description = this.palette.description;

      if (this.palette.listContext === 'ranking') {
        // @ts-ignore TS2339
        headerProps.selectPeriod = this.state.selectPeriod;
        // @ts-ignore TS2339
        headerProps.onSelectPeriodChange = this.handleSelectPeriodChange;
        // @ts-ignore TS2339
        query['period'] = this.state.selectPeriod;
        if (this.palette.genre) {
          query['genre'] = this.palette.genre;
        }
        if (this.palette.person) {
          query['person'] = this.palette.person;
        }
        if (this.palette.team) {
          query['team'] = this.palette.team;
        }
        if (this.palette.league) {
          query['league'] = this.palette.league;
        }
        if (this.palette.studio) {
          query['studio'] = this.palette.studio;
        }
        if (this.palette.attribute) {
          query['attribute'] = this.palette.attribute;
        }
        if ((browserInfo.isIOS || browserInfo.isAndroid) && this.palette.mbListLayout) {
          query['lo'] = this.palette.mbListLayout.slice(0, 1);
        }
        if (!_.isEmpty(this.palette.ratingCodes)) {
          query['sf'] = `rc:${this.palette.ratingCodes.join(',')}`;
        }
      }

      // @ts-ignore TS2339
      if (this.constructor.getListContextId(this.palette)) {
        const searchOptions = Attribute.searchOptions(this.palette, this.context.models);
        if (searchOptions.searchFilter != 'p:a') query['sf'] = searchOptions.searchFilter;

        if (this.palette.showSortFlag) {
          // @ts-ignore TS2339
          headerProps.sortOrder = this.state.sortOrder;
          // TODO: SortGalleryのdefaultPropsとして定義されているので、まとめられるならまとめた方が良い
          // @ts-ignore TS2339
          headerProps.sortOrders = [
            { so: 'po', name: '人気順' },
            { so: 'ps', name: '新着順' },
            // {so: 'yr', name: '新しい作品順'},
            // {so: 'lg', name: '古い作品順'},
            { so: 'az', name: '昇順（作品名）' },
            { so: 'za', name: '降順（作品名）' },
          ];
          // @ts-ignore TS2339
          headerProps.onSortOrderChange = this.handleSortOrderChange;
          // @ts-ignore TS2339
          query['so'] = this.state.sortOrder;
        }
      }

      // @ts-ignore TS2339
      headerProps.query = query;

      if (this.palette.listContext === 'recommended') {
        // @ts-ignore TS2339
        headerProps.id = this.props.paletteId;
        query['dc'] = this.palette.defaultContents;
      }
      if (!_.isEmpty(this.palette.linkUrl)) {
        // @ts-ignore TS2339
        headerProps.url = this.palette.linkUrl;
      } else if (this.palette.linkCanvas) {
        // @ts-ignore TS2339
        headerProps.url = routes.display.makePath({ id: this.palette.linkCanvas, service: this.context.service });
      }
    }

    let numOfRows;
    if (this.palette) {
      numOfRows = this.palette.numOfRows;
      if (this.palette.artType === 'poster' && this.palette.viewType === 'slider') {
        numOfRows = 1;
      } else if (
        this.palette &&
        (this.palette.viewType === 'slider' || this.palette.cardSize === 'small') &&
        this.palette.numOfRows == 3
      ) {
        numOfRows = 2;
      }
    }
    // @ts-ignore TS2339
    if (this.props.tabHeader) {
      // tabHeaderが存在する時は後続処理に流す
      // @ts-ignore TS2322
      if (!this.palette) return <LoadingRow isPortrait={this.state.isPortrait} />;
      // @ts-ignore TS2339
    } else if (!items || items.length === 0 || this.state.changingSortOrder || this.state.changingSelectPeriod) {
      // @ts-ignore TS2339
      if (this.props.isGrid) return null;
      // @ts-ignore TS2339
      if (!this.state.dispose) {
        return null;
      }
      // @ts-ignore TS2339
      if (this.state.changingSortOrder || this.state.changingSelectPeriod) {
        // 既に表示している時に並び替え・ランキングの期間を変更した場合のローディングは、ヘッダーを残したままにする
        return (
          <LoadingRow
            // @ts-ignore TS2322
            isPortrait={this.state.isPortrait}
            numOfRows={numOfRows}
            rowHeader={_.isEmpty(headerProps) ? null : <RowHeader {...headerProps} />}
          />
        );
      }
      // @ts-ignore TS2322
      return <LoadingRow isPortrait={this.state.isPortrait} />;
    }

    const rowProps = {};
    if (this.palette && this.palette.listContext === 'ranking') {
      // @ts-ignore TS2339
      rowProps.enableLooping = false;
    }

    const popType = () => {
      if (this.palette.artType === 'poster' || this.palette.viewType === 'list') return 'none';
      if (this.palette.listContext && this.palette.listContext.toLowerCase() === 'onrental') return 'none';
      return this.palette.popCard !== false ? this.context.popType : 'none';
    };

    const titles = _.compact(
      _.map(items, (item, index) => {
        if (listContext === 'studiolist') {
          return (
            <StudioCard
              // @ts-ignore TS2339
              model={this.props.model}
              // @ts-ignore TS2339
              key={`studio_${item.id}_${this.props.rowNum}_${index}`}
              // @ts-ignore TS2322
              rankNum={index}
              // @ts-ignore TS2339
              rowNum={this.props.rowNum}
              studioId={item.id}
              itemData={item}
            />
          );
        } else if (
          -1 !== _.indexOf(['nowonair', 'timeline', 'fromnow', 'schedule'], listContext) ||
          _.has(item, 'eventId')
        ) {
          // @ts-ignore TS2339
          rowProps.enableLooping = false;
          if (listContext === 'timeline') {
            // @ts-ignore TS2339
            rowProps.initialSlide = this.maxItems / 2;
          }
          return (
            <EventCard
              // @ts-ignore TS2322
              popType={popType()}
              // @ts-ignore TS2339
              model={this.props.model}
              // @ts-ignore TS2339
              key={`event_${item.uniqueId}_${this.props.rowNum}_${index}`}
              rankNum={index}
              ellipsized={listContext !== 'nowonair'}
              // @ts-ignore TS2339
              rowNum={this.props.rowNum}
              eventId={item.uniqueId}
              // @ts-ignore TS2339
              channelNameHidden={this.props.channelNameHidden}
              itemData={item}
              listCard={this.palette.viewType == 'list'}
              titleDisplaySetting={_.get(this.palette, 'cardTitleDisplaySetting') || 'default'}
              textArea={_.get(this.palette, 'textArea')}
            />
          );
        } else if (item.creatives && Object.keys(item.creatives).length !== 0) {
          return (
            <AdvertisingCard
              // @ts-ignore TS2322
              popType={popType()}
              // @ts-ignore TS2339
              model={this.props.model}
              // @ts-ignore TS2339
              key={`title_${item.id}_${this.props.rowNum}_${index}`}
              rankNum={index}
              // @ts-ignore TS2339
              rowNum={this.props.rowNum}
              advertisngId={item.id}
              itemData={item}
              titleDisplaySetting={_.get(this.palette, 'cardTitleDisplaySetting') || 'default'}
              listCard={this.palette.viewType == 'list'}
              titleHidden={this.palette.titleType === 'hidden' ? true : false}
            />
          );
        } else if (this.palette.schemaId == 8) {
          return (
            <LiveCard
              // @ts-ignore TS2322
              popType={popType()}
              // @ts-ignore TS2339
              model={this.props.model}
              // @ts-ignore TS2339
              key={`title_${item.id}_${this.props.rowNum}_${index}`}
              rankNum={index}
              // @ts-ignore TS2339
              rowNum={this.props.rowNum}
              titleId={item.id}
              itemData={item}
              listCard={this.palette.viewType == 'list'}
              isMediaOnly={_.get(this.palette, 'cardTitleDisplaySetting') === 'media_only' ? 'media-only' : null} // SpSliderのrenderChildrenメソッドで利用
              titleDisplaySetting={_.get(this.palette, 'cardTitleDisplaySetting') || 'default'}
              // @ts-ignore TS2339
              deliveryStartVisible={this.props.deliveryStartVisible}
            />
          );
        } else {
          if (!item.id) return;
          return (
            <TitleCard
              // @ts-ignore TS2322
              popType={popType()}
              // @ts-ignore TS2339
              model={this.props.model}
              // @ts-ignore TS2339
              key={`title_${item.id}_${this.props.rowNum}_${index}`}
              rankNum={index}
              // @ts-ignore TS2339
              rowNum={this.props.rowNum}
              titleId={item.id}
              itemData={item}
              listCard={this.palette.viewType == 'list'}
              isMediaOnly={_.get(this.palette, 'cardTitleDisplaySetting') === 'media_only' ? 'media-only' : null} // SpSliderのrenderChildrenメソッドで利用
              titleDisplaySetting={_.get(this.palette, 'cardTitleDisplaySetting') || 'default'}
              // @ts-ignore TS2339
              deliveryStartVisible={this.props.deliveryStartVisible}
            />
          );
        }
      }),
    );

    // ABテスト設定を容易にするためのID割当
    const canvasRowProps = {};
    if (_.get(this, 'palette.idKey')) {
      canvasRowProps['id'] = 'ab-' + String(this.palette.idKey);
    }

    let row;
    // @ts-ignore TS2339
    if (this.palette.viewType === 'grid' || this.props.isGrid) {
      // @ts-ignore TS2339
      if (this.props.isLast || this.props.isGrid) {
        // @ts-ignore TS2339
        if (this.props.listContext == 'genre') {
          row = (
            <GenreContent
              // @ts-ignore TS2339
              deliveryStartVisible={this.props.deliveryStartVisible}
              // @ts-ignore TS2339
              id={this.constructor.getListContextId(this.palette)}
              // @ts-ignore TS2339
              model={this.props.model}
              // @ts-ignore TS2339
              title={this.props.title}
              isGrid={true}
              // @ts-ignore TS2339
              headerVisuable={!this.props.useRowHeader}
              // @ts-ignore TS2339
              titleSmallSize={this.props.titleSmallSize}
            />
          );
        } else {
          row = (
            <PaletteContent
              // @ts-ignore TS2322
              textArea={this.props.textArea}
              // @ts-ignore TS2339
              deliveryStartVisible={this.props.deliveryStartVisible}
              // @ts-ignore TS2339
              id={this.props.paletteId}
              // @ts-ignore TS2339
              model={this.props.model}
              // @ts-ignore TS2339
              title={this.props.title}
              showSpaOnly={true}
              useHelmet={false}
              // @ts-ignore TS2339
              headerVisuable={this.props.headerVisuable === false ? false : !this.props.useRowHeader}
              // @ts-ignore TS2339
              tabHeader={this.props.tabHeader}
              // @ts-ignore TS2339
              titleSmallSize={this.props.titleSmallSize}
              // @ts-ignore TS2339
              hideAllWhenNoResults={this.props.hideAllWhenNoResults}
              // @ts-ignore TS2339
              shouldSkipGtm={this.props.shouldSkipGtm}
              // @ts-ignore TS2339
              liveSchedule={this.props.liveSchedule}
              // @ts-ignore TS2339
              cardComponent={this.props.liveSchedule ? LiveCard : null}
              // @ts-ignore TS2339
              ignoreIds={this.props.ignoreIds}
            />
          );
        }
      } else {
        let itemsInRow = this.context.columnsInRow;
        if (this.palette.artType === 'keyart') {
          if (this.context.columnsInRow == 2 || browserInfo.isIOS || browserInfo.isAndroid) {
            if (this.palette.cardSize !== 'small' && this.palette.cardSize !== 'very_small') {
              itemsInRow = this.context.columnsInRow - 1;
            }
          } else if (this.palette.cardSize === 'very_large' || this.palette.cardSize === 'large') {
            itemsInRow = Math.floor(this.context.columnsInRow / 1.5);
          } else if (!this.palette.cardSize || this.palette.cardSize === 'medium') {
            itemsInRow = this.context.columnsInRow - 1;
          }
        } else if (this.palette.artType === 'poster') {
          itemsInRow = this.context.columnsInRow + 1;
          if (this.palette.cardSize === 'very_large' || this.palette.cardSize === 'large') {
            itemsInRow = this.context.columnsInRow;
          }

          if (
            this.context.columnsInRow != 2 &&
            (this.palette.cardSize === 'very_large' || this.palette.cardSize === 'large')
          ) {
            itemsInRow = this.context.columnsInRow;
          } else if (this.context.columnsInRow != 2 && this.palette.cardSize === 'medium') {
            itemsInRow = this.context.columnsInRow + 1;
          } else if (this.context.columnsInRow != 2 && this.palette.cardSize === 'small') {
            itemsInRow = this.context.columnsInRow + 2;
          } else if (this.context.columnsInRow != 2 && this.palette.cardSize === 'very_small') {
            itemsInRow = this.context.columnsInRow + 3;
          }
        } else if (this.palette.artType === 'square') {
          if (this.context.columnsInRow == 2 || browserInfo.isIOS || browserInfo.isAndroid) {
            //スマホ
            if (this.palette.cardSize === 'very_large' || this.palette.cardSize === 'large') {
              itemsInRow = this.context.columnsInRow - 1;
            } else if (this.palette.cardSize === 'small') {
              itemsInRow = this.context.columnsInRow + 1;
            } else if (this.palette.cardSize === 'very_small') {
              itemsInRow = this.context.columnsInRow + 2;
            }
          } else if (this.context.columnsInRow != 2) {
            if (this.palette.viewType == 'grid') {
              if (this.palette.cardSize === 'very_large' || this.palette.cardSize === 'large') {
                itemsInRow = this.context.columnsInRow;
              } else if (this.palette.cardSize === 'medium') {
                itemsInRow = this.context.columnsInRow + 1;
              } else if (this.palette.cardSize === 'small') {
                itemsInRow = this.context.columnsInRow + 3;
              } else if (this.palette.cardSize === 'very_small') {
                itemsInRow = this.context.columnsInRow + 4;
              }
            } else {
              if (this.palette.cardSize === 'very_large') {
                itemsInRow = this.context.columnsInRow - 0.5;
              } else if (this.palette.cardSize === 'medium') {
                itemsInRow = this.context.columnsInRow + 1;
              } else if (this.palette.cardSize === 'small') {
                itemsInRow = this.context.columnsInRow + 3;
              }
            }
          }
        } else {
          if (this.palette.cardSize === 'very_large' || this.palette.cardSize === 'large') {
            itemsInRow = this.context.columnsInRow - 1;
          } else if (
            this.context.columnsInRow != 2 &&
            (this.palette.cardSize === 'small' || this.palette.cardSize === 'very_small')
          ) {
            itemsInRow = this.context.columnsInRow + 1;
          }
        }
        itemsInRow = Math.floor(itemsInRow);
        row = (
          <GalleryContent
            // @ts-ignore TS2322
            deliveryStartVisible={this.props.deliveryStartVisible}
            itemsInRow={itemsInRow}
            // @ts-ignore TS2339
            titleSmallSize={this.props.titleSmallSize}
          >
            {_.take(titles, itemsInRow * numOfRows)}
          </GalleryContent>
        );
      }
    } else {
      row = (
        <Row
          {...rowProps}
          totalItems={totalItems}
          // @ts-ignore TS2339
          rowNum={this.props.rowNum}
          numOfItemRows={parseInt(numOfRows, 10) || 1}
          // @ts-ignore TS2339
          loading={this.state.loading}
          handleSliderMove={this.handleSliderMove.bind(this)}
          // @ts-ignore TS2322
          handleItemClick={this.handleItemClick}
          // @ts-ignore TS2339
          listContext={this.props.listContext}
          loadingComponent={<LoadingTitle />}
          enablePaginationIndicator={false}
          enablePeek={true}
          enablePushOut={false}
          // @ts-ignore TS2339
          deliveryStartVisible={this.props.deliveryStartVisible}
        >
          {titles}
        </Row>
      );
    }
    return (
      <PaletteImpression
        paletteId={this.palette.id}
        paletteName={this.palette.title}
        // @ts-ignore TS2339
        orderNum={this.props.orderNum}
        // @ts-ignore TS2339
        useImpression={this.props.useImpression}
        // @ts-ignore TS2339
        onVisiblePalette={this.props.onVisiblePalette}
      >
        <CardContextProvider value={this.getChildContext()}>
          <div
            className={classnames('canvas-row', {
              // @ts-ignore TS2339
              'originals-panels-row': this.props.listContext === 'originals',
              'on-card':
                _.get(this.palette, 'cardTitleDisplaySetting') == 'on_card' ||
                _.get(this.palette, 'cardTitleDisplaySetting') == 'media_only',
            })}
            // @ts-ignore TS2339
            data-list-context={this.props.listContext}
            {...canvasRowProps}
          >
            {_.isEmpty(headerProps) ? null : <RowHeader {...headerProps} />}
            {row}
          </div>
        </CardContextProvider>
      </PaletteImpression>
    );
  }

  handleSliderMove() {
    this.fetchData(this.props);
  }

  handleSelectPeriodChange(sp) {
    // @ts-ignore TS2339
    if (sp !== this.state.selectPeriod) {
      // @ts-ignore TS2339
      if (this.state.dispose) this.state.dispose();
      this.setState(
        {
          dispose: null,
          generation: -1,
          fetchDataError: null,
          loading: false,
          selectPeriod: sp,
          changingSelectPeriod: true,
        },
        () => {
          this.fetchData(this.props);
        },
      );
    }
  }

  handleSortOrderChange(so) {
    // @ts-ignore TS2339
    if (so !== this.state.sortOrder) {
      // @ts-ignore TS2339
      if (this.state.dispose) this.state.dispose();
      this.setState(
        { sortOrder: so, dispose: null, generation: -1, fetchDataError: null, loading: false, changingSortOrder: true },
        () => {
          this.fetchData(this.props);
        },
      );
    }
  }

  set palette(palette) {
    // console.log(palette)
    // @ts-ignore TS2551
    this.__palette = palette;
    // @ts-ignore TS2551
    if (this.__palette) {
      // 編成で上書きされた設定を反映
      // @ts-ignore TS2339
      if (this.props.additional) {
        // @ts-ignore TS2339
        if (_.has(this.props.additional, 'title')) this.__palette.title = this.props.additional.title;
        // @ts-ignore TS2339
        if (_.has(this.props.additional, 'description')) this.__palette.description = this.props.additional.description;
        // @ts-ignore TS2339
        if (_.has(this.props.additional, 'values')) {
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'num_of_rows'))
            // @ts-ignore TS2551
            this.__palette.numOfRows = this.props.additional.values.num_of_rows;
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'art_type'))
            // @ts-ignore TS2551
            this.__palette.artType = this.props.additional.values.art_type;
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'art_kind'))
            // @ts-ignore TS2551
            this.__palette.artKind = this.props.additional.values.art_kind;
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'title_type'))
            // @ts-ignore TS2551
            this.__palette.titleType = this.props.additional.values.title_type;
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'card_title_display_setting'))
            // @ts-ignore TS2551
            this.__palette.cardTitleDisplaySetting = this.props.additional.values.card_title_display_setting;
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'text_area'))
            // @ts-ignore TS2551
            this.__palette.textArea = this.props.additional.values.text_area;
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'tips')) this.__palette.tips = this.props.additional.values.tips;
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'card_size'))
            // @ts-ignore TS2551
            this.__palette.cardSize = this.props.additional.values.card_size;
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'hide_all'))
            // @ts-ignore TS2551
            this.__palette.hideAll = this.props.additional.values.hide_all;
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'view_type'))
            // @ts-ignore TS2551
            this.__palette.viewType = this.props.additional.values.view_type;
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'pop_card'))
            // @ts-ignore TS2551
            this.__palette.popCard = this.props.additional.values.pop_card;
          // @ts-ignore TS2339
          if (_.has(this.props.additional.values, 'subscription_history_type')) {
            // @ts-ignore TS2551
            this.__palette.subscriptionHistoryType = this.props.additional.values.subscription_history_type;
          }
        }
      }

      // @ts-ignore TS2551
      if (this.__palette.elementId === 'rt_logica_top_auto_item' && this.props.canvasId) {
        const currentCanvasPalettes = _.values(
          // @ts-ignore TS2339
          _.omitBy(this.props.model.getSync(['canvas', this.props.canvasId]), v => typeof v !== 'object'),
        );
        const personalizeMusthead = _.find(
          currentCanvasPalettes,
          palette => palette.type === 'billboard' && palette.listContext === 'recommended',
        );
        if (personalizeMusthead) {
          const personalizeMustheadObjects = _.values(
            _.omitBy(
              // @ts-ignore TS2339
              this.props.model.getSync(['palette', personalizeMusthead.id, 'objects']),
              v => typeof v !== 'object',
            ),
          );
          const userRecommend = _.find(
            personalizeMustheadObjects,
            obj => _.get(obj, 'selectedBy') === 'user_recommend',
          );
          // @ts-ignore TS2551
          if (userRecommend) this.__palette.defaultContents = `${userRecommend.id}:ur`;
        }
      }

      // @ts-ignore TS2551
      if (this.__palette.objects) {
        // 未ログインユーザー：0のみ表示
        // ログインユーザー：subscriptionHistoryFlagを確認
        // subscriptionHistoryFlagがtrueの場合、subscriptionHistoryTypeが0、1を表示する
        // subscriptionHistoryFlagがfalseの場合、subscriptionHistoryTypeが0、2を表示する

        // @ts-ignore TS2339
        const listContext = this.props.listContext ? this.props.listContext.toLowerCase() : null;
        if (-1 !== _.indexOf(['timeline', 'fromnow', 'schedule'], listContext)) {
          // 設定しない
        } else {
          const browserInfo = this.context.getModelData('browserInfo');
          // @ts-ignore TS2551
          this.items = _.compact(_.values(_.omit(this.__palette.objects, ['$__path', 'length'])));
          // スマホの場合は全件
          if (browserInfo.isIOS || browserInfo.isAndroid) {
            // @ts-ignore TS2551
            this.totalItems = this.__palette.objects.length;
          } else {
            // @ts-ignore TS2551
            this.totalItems = Math.min(this.__palette.objects.length, this.maxItems);
          }
          // @ts-ignore TS2339
          if (this.props.noContents && !this.totalItems) this.props.noContents(this.props.paletteId);
        }
      }
      this.overwriteFromFirstAd();
    }
  }

  get palette() {
    // @ts-ignore TS2551
    return this.__palette;
  }

  set items(items) {
    const profile = activeProfile(this.context.models, false);
    const subscriptionHistoryFlag = _.get(profile, 'subscriptionHistoryFlag', false);
    // @ts-ignore
    const subscriptionHistoryType = _.get(this.__palette, 'subscriptionHistoryType') ?? '0';
    // 契約履歴での出しわけ
    const showSubscriptionPalette =
      subscriptionHistoryType === '0' ||
      (subscriptionHistoryType === '1' && subscriptionHistoryFlag && profile) ||
      (subscriptionHistoryType === '2' && !subscriptionHistoryFlag && profile);
    // $__pathなどだけで構成されている場合にとるつめされないので、ここで共通で抜く
    if (showSubscriptionPalette) {
      // @ts-ignore TS2551
      this.__items = _.reject(
        _.map(_.clone(items), item =>
          _.omitBy(item || [], (__, key) => key.indexOf('$') === 0 || key === 'length' || key === 'lastEvaluatedKey'),
        ),
        item => {
          if (_.isEmpty(item || {})) {
            // @ts-ignore TS2339
            this.rejectCount = (this.rejectCount || 0) + 1;
            return true;
          }
          return false;
        },
      );
    } else {
      // @ts-ignore TS2551
      this.__items = [];
      this.totalItems = 0;
      // @ts-ignore TS2551
      if (this.props.noContents) {
        // @ts-ignore TS2551
        this.props.noContents(this.props.paletteId);
      }
    }

    this.overwriteFromFirstAd();
  }

  get totalItems() {
    // @ts-ignore TS2551
    return (this.__totalItems || 0) - (this.rejectCount || 0);
  }

  set totalItems(totalItems) {
    // @ts-ignore TS2551
    this.__totalItems = totalItems;
  }

  get items() {
    // @ts-ignore TS2551
    return this.__items;
  }

  overwriteFromFirstAd() {
    if (_.get(this.items, '0.advertisingId') && routes[this.palette.listContext]) {
      // @ts-ignore TS2551
      const firstCard = this.__items[0];
      // @ts-ignore TS2339
      const linkProps = AdvertisingCard.getLinkProps(firstCard);
      let url = linkProps.href ? linkProps.href : linkProps.to.makePath(linkProps.params);
      const host = _.get(this.context.getModelData('hosts'), 'host');
      url = MainViewLink.replaceUrl(url, host);
      const { path } = parseUrl(url);
      // 先頭に広告があって、その広告のURLがパレットの詳細を示している場合
      if (path === routes[this.palette.listContext].makePath({ id: this.palette.id })) {
        // そのパレットにタイトルが登録されていないときはその広告のタイトルをパレットのタイトル表示部分に代わりに利用する
        // @ts-ignore TS2551
        if (!this.__palette.title && firstCard.name) {
          // @ts-ignore TS2551
          this.__palette.title = firstCard.name;
        }
        // そのパレットに説明が登録されていないときはその広告の説明をパレットの説明表示部分に代わりに利用する
        // @ts-ignore TS2551
        if (!this.__palette.description && _.get(firstCard, 'cardInfo.description')) {
          // @ts-ignore TS2551
          this.__palette.description = firstCard.cardInfo.description;
        }
      }
    }
  }

  isUseItemsLength(listContext) {
    return listContext == 'recommended';
  }

  fetchData(props, context = this.context) {
    // console.log('fetchDate')
    // @ts-ignore TS2339
    const listContext = this.props.listContext.toLowerCase();
    if (!this.isUseItemsLength(listContext)) {
      // lengthがなければキャッシュを消して取り直す
      if (
        window.app.appContext.getFalcorModel()._root.cache.palette &&
        window.app.appContext.getFalcorModel()._root.cache.palette[props.paletteId] &&
        !_.get(window.app.appContext.getFalcorModel()._root.cache, `palette.${props.paletteId}.objects.length`)
      ) {
        delete window.app.appContext.getFalcorModel()._root.cache.palette[props.paletteId].objects;
      }
    }
    const browserInfo = this.context.getModelData('browserInfo');
    const pathProps = Object.assign(
      {},
      props,
      _.pick(this.palette, [
        'genre',
        'person',
        'team',
        'league',
        'studio',
        'attribute',
        'meta',
        'linearChannelMeta',
        'listContext',
        'sortOrder',
        'filterType',
        'period',
        'recommendKey',
        'elementId',
        'genres',
        'middleGenres',
        'defaultContents',
        'ratingCodes',
        'tSearch',
        'sSearch',
        'subscriptionHistoryType',
      ]),
    );
    if (this.items) {
      if (!this.items.length) return;
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        // スマホの場合は全件なので分割して取得する
        pathProps.toNum = Math.min(this.items.length + 18, this.totalItems);
      } else {
        // @ts-ignore TS2339
        this.totalItems = Math.min(this.maxItems, this.totalItems);
        pathProps.toNum = this.totalItems <= 19 ? 18 : 39;
      }
      // おすすめはアクセス毎で結果が変わる為
    } else if (listContext === 'recommended') {
      // @ts-ignore TS2339
      pathProps.toNum = this.maxItems;
    } else if (listContext === 'timeline') {
      // @ts-ignore TS2339
      pathProps.toNum = this.maxItems / 2;
    }

    // @ts-ignore TS2339
    if (this.state.selectPeriod) {
      // @ts-ignore TS2339
      pathProps.period = this.state.selectPeriod;
    }

    // @ts-ignore TS2339
    if (this.state.sortOrder) {
      // @ts-ignore TS2339
      pathProps.sortOrder = this.state.sortOrder;
    }

    if (_.includes(USE_LAST_EVALUATED_KEY, listContext)) {
      // 非公開メタなども取れてしまう場合があるため
      // メタが取れていて、ハッシュがない場合は全件取得済みなのでfetchしない
      // @ts-ignore TS2339
      if (!this.lastEvaluatedKey && !_.isEmpty(this.items)) {
        return;
      }
      // @ts-ignore TS2339
      pathProps.lastEvaluatedKey = this.lastEvaluatedKey;
      pathProps.fromNum = !_.isEmpty(this.items) ? this.items.length : 0;
    }
    const minTitlesToFetch = (context.columnsInRow || 0) + 1;

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;
    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    this.setState({ loading: true });

    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.palette = _.get(res, `json.palette.${this.props.paletteId}`);
        if (!this.palette.objects) {
          let path, fallbackPath;
          if (this.palette.listContext === 'ranking') {
            const searchFilter = _.isEmpty(this.palette.ratingCodes)
              ? 'p:a'
              : `rc:${this.palette.ratingCodes.join(',')}`;
            // @ts-ignore TS2339
            path = `json.ranking.${this.state.selectPeriod}.${Ranking.getAttributeFilter(
              this.palette,
            )}.${searchFilter}`;
          } else if (this.palette.listContext === 'user_recommend') {
            path = `json.${listContext}.${this.palette.recommendKey}.su`;
            fallbackPath = `json.${listContext}.${this.palette.recommendKey}`;
            this.palette.title = _.get(
              res,
              // @ts-ignore TS2339
              `json.${this.props.listContext}.${this.palette.recommendKey}.palette_title`,
              this.palette.title,
            );
            // @ts-ignore TS2339
          } else if (this.constructor.getListContextId(this.palette)) {
            const searchOptions = Attribute.searchOptions(
              // @ts-ignore TS2339
              Object.assign({}, this.palette, { sortOrder: this.state.sortOrder }),
              this.context.models,
            );
            // @ts-ignore TS2339
            path = `json.${this.props.listContext}.${this.constructor.getListContextId(this.palette)}.${
              searchOptions.filterType
            }.${searchOptions.sortOrder}.${searchOptions.searchFilter}`;
          } else if (this.palette.meta) {
            // @ts-ignore TS2339
            if (this.props.listContext === 'relateds') {
              // @ts-ignore TS2339
              path = `json.meta.${this.palette.meta}.${this.props.listContext}.${this.palette.sortOrder || 'su'}`;
            } else {
              // @ts-ignore TS2339
              path = `json.${this.props.listContext}.${this.palette.meta}.${this.palette.sortOrder || 'po'}`;
            }
          } else {
            if (this.palette.sortOrder && this.palette.sortOrder.length > 0) {
              path = `json.${listContext}.${this.palette.sortOrder}`;
            } else if (-1 !== _.indexOf(['timeline', 'fromnow', 'schedule'], listContext)) {
              path = `json.${listContext}.${this.palette.linearChannelMeta}`;
              // @ts-ignore TS2339
              this.linearChannelMeta = _.get(res, `json.meta.${this.palette.linearChannelMeta}`);
            } else if (this.palette.listContext === 'recommended') {
              path = `json.${listContext}.${this.palette.elementId}.${this.palette.genres || 0}.${this.palette
                .defaultContents || 0}`;
            } else if (_.includes(USE_LAST_EVALUATED_KEY, listContext)) {
              const { from, to } = indexes(context.models, {}, pathProps);
              // @ts-ignore TS2339
              path = `json.${listContext}.${this.lastEvaluatedKey || PAGING_INITIAL_KEY}.${to - from + 1}`;
              // @ts-ignore TS2339
              this.lastEvaluatedKey = _.get(res, `${path}.lastEvaluatedKey`);
            } else {
              path = `json.${listContext}.su`;
              fallbackPath = `json.${listContext}`;
            }
          }
          let _items = _.compact(
            _.values(
              _.omit(_.get(res, path, _.get(res, fallbackPath)), [
                '$__path',
                'length',
                'palette_title',
                'lastEvaluatedKey',
              ]),
            ),
          );
          if (_.includes(USE_LAST_EVALUATED_KEY, listContext)) {
            _items = this.items ? this.items.concat(_items) : _items;
          }
          this.items = _items;
          const length = this.isUseItemsLength(listContext)
            ? this.items.length
            : _.get(
                res,
                `${path}.length`,
                // @ts-ignore TS2339
                _.get(res, `${fallbackPath}.length`, _.get(res, `json.${this.props.listContext}.length`, 0)),
              );
          // スマホの場合は全件
          if (browserInfo.isIOS || browserInfo.isAndroid) {
            this.totalItems = length;
          } else {
            // @ts-ignore TS2339
            this.totalItems = Math.min(this.maxItems, length);
          }
          // @ts-ignore TS2339
          if (_.includes(USE_LAST_EVALUATED_KEY, listContext) && !this.lastEvaluatedKey) {
            // 全件取得済み
            this.totalItems = this.items.length;
          } else if (listContext === 'nowonair') {
            this.items =
              _.flatten(
                _.map(this.items, item => {
                  return _.values(_.omit(item, ['$__path', 'length']));
                }),
              ) || [];
            this.totalItems = this.items.length;
          }
        }

        // レアケースだが取得したメタ非公開ばかりの場合があり得るので、
        // columnsInRow+1は最初のfetchで取得するようにする
        if (minTitlesToFetch && minTitlesToFetch > this.items.length && minTitlesToFetch < this.totalItems) {
          // @ts-ignore TS2339
          this.refetchFlag = true;
          // @ts-ignore TS2339
        } else if (this.refetchFlag) {
          // @ts-ignore TS2339
          delete this.refetchFlag;
        }

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
          loading: false,
          changingSelectPeriod: false,
          changingSortOrder: false,
        };

        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState(newState, () => {
            // @ts-ignore TS2339
            if (!this.refetchFlag) return;
            this.fetchData(props, context);
          });
        } else {
          Object.assign(this.state, newState);
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }
}

export default Palette;
